<template>
  <div class="wish-detail">
    <!-- <van-nav-bar title="我要写信" left-arrow @click-left="onClickLeft" /> -->
    <div class="wish-detail-body">
      <div class="form">
        <van-field
          v-model="message"
          rows="6"
          autosize
          type="textarea"
          maxlength="140"
          placeholder="请填写你想要的的话"
          show-word-limit
        />
        <!-- multiple
          :max-count="3" -->
        <van-uploader
          v-model="fileList"
          :max-count="3"
          multiple
          :before-read="beforeRead"
          :after-read="afterRead"
          result-type="dataUrl"
        />
        <div class="public"></div>
        <van-radio-group v-model="radio" direction="horizontal">
          <van-cell title="是否公开">
            <template #right-icon>
              <van-radio name="0" checked-color="#FF192F" icon-size="20px">私信</van-radio>
              <van-radio name="1" checked-color="#FF192F" icon-size="20px">公开信</van-radio>
            </template>
          </van-cell>
        </van-radio-group>
        <van-cell title="当前选择" is-link :value="chooseValue" @click="openF" v-if="radio==0" />
        <van-popup v-model="show" position="bottom" :style="{ height: '30%' }" class="choose">
          <van-radio-group v-model="secretaryName" @change="chooseSec">
            <van-radio
              :name="item.secretaryName"
              checked-color="#FF192F"
              icon-size="20px"
              v-for="item in secretaryList"
              :key="item.id"
            >{{item.secretaryName}}</van-radio>
          </van-radio-group>
        </van-popup>
      </div>
      <button class="post" @click="submitLetter">提交</button>
    </div>
  </div>
</template>
<script>
import { Notify } from 'vant';
import qs from 'qs';
import { formateTime } from '@/utils/util';
import { writeLetter, requestSecre, uploadFile } from '@/api/heartWord';
export default {
  data() {
    return {
      message: '',
      //图片
      fileList: [],
      radio: '0',
      //书记选择
      secretaryName: 0,
      show: false,
      chooseValue: '选择书记',
      secretaryList: []
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go({ name: 'heartWord' });
    },
    //打开弹出
    openF() {
      this.show = true;
    },
    //选择书记
    chooseSec() {
      this.show = false;
      this.chooseValue = this.secretaryName;
    },
    // 上传前校验
    beforeRead(file) {
      console.log(this.fileList);
      if (Array.isArray(file)) {
        let notType = false;
        file.forEach(item => {
          if (
            item.type !== 'image/jpeg' &&
            item.type !== 'image/png' &&
            item.type !== 'image/gif'
          ) {
            notType = true;
          }
        });
        if (notType) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
        if (file.length > 3 - this.fileList.length) {
          Notify({
            type: 'warning',
            message: '您上传的张数超过上限，请重新选择'
          });
          return false;
        }
      } else {
        if (
          file.type !== 'image/jpeg' &&
          file.type !== 'image/png' &&
          file.type !== 'image/gif'
        ) {
          Notify({ type: 'warning', message: '请上传 jpg/png/gif格式图片' });
          return false;
        }
      }

      return true;
    },
    // 上传服务器
    afterRead(file) {
      const this_ = this;
      if (Array.isArray(file)) {
        file.forEach(fileItem => {
          console.log(fileItem, this.fileList);
          fileItem.status = 'uploading';
          fileItem.message = '上传中...';
          const formdata = new FormData();
          formdata.append('file', file.file);
          lrz(fileItem.file, {})
            .then(async function(rst) {
              //成功时执行
              console.log('压缩成功');
              const formdata = rst.formData;
              await uploadFile(formdata)
                .then(res => {
                  res = res.data;
                  if (res.success) {
                    fileItem.status = 'done';
                    fileItem.url = res.data.link;
                    Notify({ type: 'success', message: '上传成功' });
                  } else {
                    fileItem.status = 'failed';
                    fileItem.message = '上传失败';
                    Notify({ type: 'warning', message: '上传失败' });
                  }
                })
                .catch(err => {
                  fileItem.status = 'failed';
                  fileItem.message = '上传失败';
                  Notify({ type: 'warning', message: '上传失败' });
                  console.log(err);
                });
            })
            .catch(function(error) {
              Notify({ type: 'warning', message: '图片压缩失败' });
              //失败时执行
            })
            .always(function() {
              //不管成功或失败，都会执行
            });
        });
      } else {
        console.log(file, this.fileList);
        file.status = 'uploading';
        file.message = '上传中...';
        // const formdata = new FormData();
        // formdata.append('file', file.file);
        lrz(file.file, {})
          .then(function(rst) {
            //成功时执行
            console.log('压缩成功');
            const formdata = rst.formData;
            uploadFile(formdata)
              .then(res => {
                res = res.data;
                if (res.success) {
                  file.status = 'done';
                  file.url = res.data.link;
                  Notify({ type: 'success', message: '上传成功' });
                } else {
                  this_.fileList = this_.fileList.slice(
                    0,
                    this_.fileList.length - 1
                  );
                  Notify({ type: 'warning', message: '上传失败' });
                }
              })
              .catch(err => {
                this_.fileList = this_.fileList.slice(
                  0,
                  this_.fileList.length - 1
                );
                Notify({ type: 'warning', message: '上传失败' });
                console.log(err);
              });
          })
          .catch(function(error) {
            Notify({ type: 'warning', message: '图片压缩失败' });
            //失败时执行
          })
          .always(function() {
            //不管成功或失败，都会执行
          });
      }
    },
    //提交
    submitLetter() {
      const fileList = this.fileList.map(item => item.url);
      // let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      let selected = this.secretaryList.filter(
        item => item.secretaryName === this.chooseValue
      );
      if (!this.message) {
        Notify({ type: 'warning', message: '请填写你要说的话' });
        return false;
      }
      if (this.radio == '0' && !selected.length) {
        Notify({ type: 'warning', message: '请选择书记' });
        return false;
      }

      //写信
      writeLetter({
        sendMessage: this.message,
        isPublic: Number(this.radio),
        sendTarget: this.radio == '1' ? null : selected[0].id,
        picture: fileList.join(',')
        //图片
      })
        .then(res => {
          if (res.success) {
            // this.$router.push({ name: 'heartWord' });
            this.$router.go(-1);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    let username = JSON.parse(localStorage.getItem('user')).realName;
    //请求书记列表

    requestSecre().then(res => {
      this.secretaryList = res.data.filter(
        item => item.secretaryName !== username
      );
    });
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/75;
.wish-detail {
  display: flex;
  height: 100%;
  flex-direction: column;
  .van-nav-bar {
    height: 88 * $px;
    /deep/ .van-icon {
      color: #222;
    }
  }
  .wish-detail-body {
    background: #f3f3f3;
    flex: 1;
    overflow: auto;
    .form {
      background: #fff;
      .van-uploader {
        padding: 24 * $px 0 30 * $px 24 * $px;
      }
      .van-radio-group {
        padding-top: 20 * $px;
        background: #f3f3f3;
      }
      .choose {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 30 * $px 0;
        .van-radio-group {
          background: #fff;
          .van-radio {
            background: #fff;
            height: 80 * $px;
            font-size: 32 * $px;
          }
        }
      }
    }
    .post {
      width: 690 * $px;
      height: 88 * $px;
      background: rgba(255, 25, 47, 1);
      border: 0;
      font-size: 32 * $px;
      color: rgba(255, 255, 255, 1);
      border-radius: 44 * $px;
      margin: 40 * $px 30 * $px;
    }
  }
}
</style>